/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
// import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  ATMButton,
  ATMDivider,
  ATMGrid,
  ATMLoader,
  ATMMessage,
  ATMPopover,
  ATMSegment,
  ATMTab,
  MOLRightPanel,
} from 'shared-it-appmod-ui';
import {
  AccessRole,
  AccessType,
  // lerRequestFormStep,
  LERRequestFormStep,
  LERRequestPage,
  LERRequestStatus,
  LERRequestWidth,
} from 'src/constants';
import Lang from 'src/libraries/language';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasAccess, hasRole } from 'src/libraries/access.library';
import LerRequestHeader from 'src/components/organisms/ler-request-header/ler-request-header.component';
import {
  getLerOutageDateList,
  hasChangeRequest,
  hasEditAccess,
} from 'src/helpers/ler-request.helper';
import Access from 'src/components/atoms/access/access.component';
import { IFileSharePoint } from 'src/models/file.model';
import Moment from 'src/libraries/moment.library';
import { useRolesContext } from 'src/contexts/roles.context';
import { useAuthorizedListContext } from 'src/contexts/authorized-list.context';
import LERRequestOutageFacility from '../ler-request-outage-facility/ler-request-outage-facility.component';
import LerRequestDocumentsDetail from '../ler-request-documents/ler-request-documents-detail.component';
import LERRequestAuthorization from '../ler-request-authorization/ler-request-authorization.component';
import LERRequestCostDetail from '../ler-request-cost/ler-request-cost.component';
import LERRequestIsoDetail from '../ler-request-iso/ler-request-iso-detail/ler-request-iso-detail.component';
import LERRequestJobInformation from '../ler-request-job-information/ler-request-job-information.component';
import LerRequestOutageDateDetail from '../ler-request-outage-date/ler-request-outage-date-detail/ler-request-outage-date-detail.component';
import LERRequestSwitchingDetail from '../ler-request-switching/ler-request-switching-detail/ler-request-switching-detail.component';
import LERRequestSystemChangeDetail from '../ler-request-system/ler-request-system-detail/ler-request-system-detail.component';
import styles from './ler-request-detail.module.scss';
import LerRequestPendingRequest from '../ler-request-pending-request/ler-request-pending-request.component';
import LerRequestVersion from '../ler-request-version-control/ler-request-version-control-list.component';
import LerRequestApprove from '../ler-request-approve/ler-request-approve.component';
import LerRequestReject from '../ler-request-reject/ler-request-reject.component';
import LerRequestActionList from '../ler-request-action-list/ler-request-action-list.component';
import LerRequestSwitchingManager from '../ler-request-switching/ler-request-switching-manager/ler-request-switching-manager.component';
import LERRequestCrewInformation from '../ler-request-crew-information/ler-request-crew-information.component';

type IContentProps = {
  id: number;
  version?: number;
  active?: LERRequestFormStep;
  setClose: () => void;
};

const CHANGE_REQUEST = 'change_request';
const VERSION_CONTROL = 'version_control';

const Content: React.FC<IContentProps> = ({
  id,
  version,
  setClose,
  active,
}) => {
  const { getUrl } = useLocationParams([
    LERRequestPage.Edit,
    LERRequestPage.View,
    'active',
  ]);
  // useLocationParams([
  //   LERRequestPage.Edit,
  //   LERRequestPage.View,
  // ]);
  const {
    state: { data },
    actions,
  } = useLerRequestContext();

  const {
    state: { outageList },
    actions: rolesAction,
  } = useRolesContext();

  const {
    actions: authListAction,
    state: { crewList },
  } = useAuthorizedListContext();

  const [tabIndex, setTabIndex] = useState<
    LERRequestFormStep | typeof CHANGE_REQUEST | typeof VERSION_CONTROL
  >(active || LERRequestFormStep.OUTAGE_DATE);

  const [lerDocuments, setLerDocuments] = useState<
    IFileSharePoint[] | undefined
  >(undefined);

  const [printLoader, setPrintLoader] = useState<boolean>(false);

  // const handleApprove = useCallback(() => {
  //   actions.setForApproval(true);
  //   setTabIndex(lerRequestFormStep.OUTAGE_DATE);
  // }, [setTabIndex]);

  const isChangeRequest = hasChangeRequest(data);
  const isVersionMaxedForCR =
    data &&
    data.version_no &&
    data.version_no >= 99 &&
    (([
      LERRequestStatus.Submitted,
      LERRequestStatus.Approved,
      LERRequestStatus.Scheduled,
    ].includes(data.requestStat as LERRequestStatus) &&
      hasRole([AccessRole.MCC_OUTAGE_REQUESTOR])) ||
      ([LERRequestStatus.Approved, LERRequestStatus.Scheduled].includes(
        data.requestStat as LERRequestStatus
      ) &&
        hasRole([AccessRole.MCC_SUBMITTING_SUPERVISOR])));

  useEffect(() => {
    const fetchData = async () => {
      const isForSwitchingStat = true;
      const result = await actions.dataGET(id, version, isForSwitchingStat);
      actions.setCaisoList();
      await actions.versionGET(id);

      if (result.error) {
        setClose();
      } else {
        // This is needed for reconcilation web service call which can be triggered on View mode
        // when triggering LER Reject (ellipses)
        // eslint-disable-next-line no-lonely-if
        if (result.payload) {
          actions.setPreviousData(result.payload);
        }
      }
    };
    fetchData();
    const getCrewList = async () => {
      await authListAction.listCrewGET(
        Moment()
          .set({
            date: 31,
            month: 11,
            year: new Date().getFullYear() - 2,
          })
          .toDate()
      );
    };
    const getRolesList = async () => {
      await rolesAction.outageListGET();
    };
    if (!crewList?.length && !data?.crewInformation?.crew) {
      getCrewList();
    }
    if (
      !outageList?.length &&
      (!data?.crewInformation?.requestor || !data?.crewInformation?.supervisor)
    ) {
      getRolesList();
    }
  }, [id, version, actions, setClose]);

  if (!data || (data && data.requestId !== id)) {
    return <ATMLoader active />;
  }

  const panes = [
    {
      key: LERRequestFormStep.OUTAGE_DATE,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.OUTAGE_DATE],
      render: <LerRequestOutageDateDetail id={id} />,
    },
    {
      key: LERRequestFormStep.JOB_INFORMATION,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.JOB_INFORMATION],
      render: (
        <LERRequestJobInformation
          data={data[LERRequestFormStep.JOB_INFORMATION]}
          isView={false}
        />
      ),
    },
    {
      key: LERRequestFormStep.CREW_INFORMATION,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.CREW_INFORMATION],
      render: (
        <LERRequestCrewInformation
          data={data[LERRequestFormStep.CREW_INFORMATION]}
        />
      ),
    },
    {
      key: LERRequestFormStep.AUTHORIZATION_STATEMENT,
      access: AccessType.OUTAGE_REQUEST_AUTH_STATEMENT,
      menuItem:
        Lang.LER_REQUEST_STEP[LERRequestFormStep.AUTHORIZATION_STATEMENT],
      render: <LERRequestAuthorization data={data} />,
    },
    {
      key: LERRequestFormStep.ISO,
      access: AccessType.OUTAGE_REQUEST_ISO,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.ISO],
      render: <LERRequestIsoDetail data={data} />,
    },
    {
      key: LERRequestFormStep.SWITCHING,
      access: AccessType.OUTAGE_REQUEST_SWITCHING,
      menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.SWITCHING],
      render: hasRole(AccessRole.MCC_REAL_TIME_OPERATOR) ? (
        <LerRequestSwitchingManager data={data} />
      ) : (
        <LERRequestSwitchingDetail data={data} />
      ),
    },
  ].filter((v) => !v.access || hasAccess(v.access));

  if (
    (hasRole([
      AccessRole.MCC_OUTAGE_REQUESTOR,
      AccessRole.MCC_SUBMITTING_SUPERVISOR,
      AccessRole.MCC_OPERATIONAL_ENGINEER,
    ]) &&
      [LERRequestStatus.Approved, LERRequestStatus.Scheduled].includes(
        data.requestStat as LERRequestStatus
      )) ||
    hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
  ) {
    panes.push(
      {
        key: LERRequestFormStep.SYSTEM_CHANGE,
        menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.SYSTEM_CHANGE],
        render: <LERRequestSystemChangeDetail data={data} />,
      },
      {
        key: LERRequestFormStep.COST,
        menuItem: Lang.LER_REQUEST_STEP[LERRequestFormStep.COST],
        render: <LERRequestCostDetail data={data} />,
      }
    );
  }

  if (isChangeRequest && hasAccess(AccessType.OUTAGE_REQUEST_CHANGE_APPROVE)) {
    panes.push({
      key: CHANGE_REQUEST,
      menuItem: {
        icon: 'exclamation circle',
        content: Lang.LBL_CHANGE_REQUEST,
      },
    } as any);
  }

  panes.push({
    menuItem: Lang.LBL_OC_LER_VER_CONTROL,
    key: VERSION_CONTROL,
  } as any);

  const isCompletedOrCancelled = [
    LERRequestStatus.Completed,
    LERRequestStatus.Cancelled,
  ].includes(data.requestStat as LERRequestStatus);

  const isValidForRejection =
    [LERRequestStatus.Scheduled, LERRequestStatus.Approved].includes(
      data.requestStat as LERRequestStatus
    ) && !isChangeRequest;

  const header = (
    <div
      className={
        isChangeRequest && hasAccess(AccessType.OUTAGE_REQUEST_CHANGE_APPROVE)
          ? styles.header2
          : styles.header
      }
    >
      <ATMSegment attached>
        <ATMGrid columns={2}>
          <ATMGrid.Column verticalAlign="middle" width={14}>
            <LerRequestHeader data={data} />
          </ATMGrid.Column>
          <ATMGrid.Column textAlign="right" verticalAlign="middle" width={2}>
            <div className={styles.right}>
              <div className="action-buttons">
                {isCompletedOrCancelled === false && (
                  <>
                    <Access
                      type={AccessType.OUTAGE_UPDATE}
                      when={hasEditAccess(data)}
                    >
                      {isVersionMaxedForCR ? (
                        <ATMPopover
                          position="bottom left"
                          content={
                            <span>
                              This LER has reached the maximum number of allowed
                              Change Requests. You have the option to{' '}
                              <strong>Clone</strong>.
                            </span>
                          }
                          on="hover"
                          trigger={
                            <ATMButton
                              primary
                              content={Lang.LBL_EDIT}
                              to={undefined}
                              loading={printLoader}
                            />
                          }
                        />
                      ) : (
                        <ATMButton
                          as={Link}
                          type="button"
                          primary
                          content={Lang.LBL_EDIT}
                          to={getUrl({
                            [LERRequestPage.Edit]: data.requestId,
                            [LERRequestPage.View]: undefined,
                            active: tabIndex,
                          })}
                          loading={printLoader}
                        />
                      )}
                    </Access>

                    <LerRequestReject
                      data={data}
                      handleSuccess={() => {
                        setClose();
                      }}
                      isRejectAfterSchedule={isValidForRejection}
                      printLoader={printLoader}
                    />
                    <LerRequestApprove
                      data={data}
                      handleSuccess={() => {
                        if (
                          hasRole(AccessRole.MCC_OUTAGE_SCHEDULER) === false
                        ) {
                          setClose();
                        }
                      }}
                      printLoader={printLoader}
                    />
                  </>
                )}

                <LerRequestActionList
                  data={data}
                  setClose={setClose}
                  docList={lerDocuments}
                  page={LERRequestPage.View}
                  setPrintLoader={setPrintLoader}
                  printLoader={printLoader}
                />

                <ATMButton
                  color="grey"
                  basic
                  icon="external alternate"
                  onClick={() => {
                    window.open(window.location.href, '_blank')?.focus();
                  }}
                />
                <ATMButton
                  type="button"
                  color="grey"
                  basic
                  icon="close"
                  onClick={() => {
                    setClose();
                  }}
                />
              </div>
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column
            verticalAlign="middle"
            width={16}
            className={styles.headerLine}
          >
            <ATMTab
              panes={panes.map(({ render, ...item }) => item)}
              activeIndex={panes.findIndex((v) => v.key === tabIndex)}
              onTabChange={(_, value) => {
                if (value.activeIndex !== undefined && value.panes) {
                  const key = value.panes[value.activeIndex]
                    .key as LERRequestFormStep;
                  setTabIndex(key);
                }
              }}
              menu={{ secondary: true, pointing: true }}
            />
          </ATMGrid.Column>
        </ATMGrid>
      </ATMSegment>
    </div>
  );
  const content = (
    <>
      <ATMSegment className={styles.content}>
        {isChangeRequest && (
          <>
            <ATMMessage color="orange" className={styles.message}>
              <ATMGrid>
                <ATMGrid.Row columns={2}>
                  <ATMGrid.Column>
                    <h5>{Lang.TTL_CHANGE_REQUEST_PENDING}</h5>
                    <p>
                      {hasRole(AccessRole.MCC_OUTAGE_REQUESTOR)
                        ? Lang.MSG_LER_CHANGE_REQUEST_REQUESTOR
                        : Lang.MSG_LER_CHANGE_REQUEST}
                    </p>
                  </ATMGrid.Column>
                  <Access type={AccessType.OUTAGE_REQUEST_CHANGE_APPROVE}>
                    <ATMGrid.Column textAlign="right" verticalAlign="middle">
                      <ATMButton
                        primary
                        type="button"
                        content={Lang.LBL_LER_VIEW_CHNG_REQ}
                        onClick={() => {
                          setTabIndex(CHANGE_REQUEST);
                        }}
                      />
                    </ATMGrid.Column>
                  </Access>
                </ATMGrid.Row>
              </ATMGrid>
            </ATMMessage>
          </>
        )}
        <ATMGrid divided>
          <ATMGrid.Column
            width={LERRequestWidth.LEFT}
            className="panel-full-height"
          >
            <div className={styles.container}>
              {panes.map(({ key, render }) => (
                <div
                  key={key}
                  className={tabIndex !== key ? 'hidden' : undefined}
                >
                  {render}
                </div>
              ))}
            </div>
          </ATMGrid.Column>
          {tabIndex === LERRequestFormStep.SWITCHING &&
          hasRole(AccessRole.MCC_REAL_TIME_OPERATOR) ? (
            ''
          ) : (
            <ATMGrid.Column width={LERRequestWidth.RIGHT}>
              <LERRequestOutageFacility
                data={data.outageFacility}
                details={data}
              />
              <ATMDivider />
              <LerRequestDocumentsDetail
                id={id}
                viewType="view"
                setLerDocuments={setLerDocuments}
              />
            </ATMGrid.Column>
          )}
          {/* {tabIndex !== LERRequestFormStep.SWITCHING && (
            <ATMGrid.Column width={LERRequestWidth.RIGHT}>
              <LERRequestOutageFacility
                data={data.outageFacility}
                details={data}
              />
              <ATMDivider />
              <LerRequestDocumentsDetail
                id={id}
                viewType="view"
                setLerDocuments={setLerDocuments}
              />
            </ATMGrid.Column>
          )} */}
        </ATMGrid>
      </ATMSegment>
    </>
  );

  return (
    <>
      {header}
      {tabIndex === VERSION_CONTROL ? (
        <LerRequestVersion requestId={data.requestId} />
      ) : tabIndex === CHANGE_REQUEST ? ( // Replace null with Version Control Component
        <LerRequestPendingRequest
          data={{
            ...data,
            outageDates: getLerOutageDateList(data.outageDates),
          }}
          // handleApprove={handleApprove}
          setClose={setClose}
        />
      ) : (
        content
      )}
    </>
  );
};

type IProps = {
  trigger?: React.ReactNode;
};

const LerRequestDetails: React.FC<IProps> = ({ trigger }) => {
  const { params, handleChange } = useLocationParams([
    LERRequestPage.View,
    'active',
  ]);

  const id = params[LERRequestPage.View];
  const { active } = params;

  return (
    <>
      <MOLRightPanel
        isOpen={id}
        onClose={() => handleChange()}
        trigger={trigger}
        className={styles.floatingPanel}
        width="calc(100vw - 200px)"
      >
        <Content
          id={Number(id)}
          setClose={() => handleChange()}
          active={active}
        />
      </MOLRightPanel>
    </>
  );
};
export default React.memo(LerRequestDetails);
