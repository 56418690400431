/* eslint-disable no-nested-ternary */
import React, { Dispatch, SetStateAction, useCallback } from 'react';

type IProps = {
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  options: string[];
  numStates: number;
};

const SwitchThreeWayToggle: React.FC<IProps> = ({
  state,
  setState,
  options,
  numStates = 3, // Default to 3 states if not provided
}) => {
  const handleToggle = useCallback(() => {
    const toggleIndex = options.findIndex((v) => v === state);

    const nextIndex = (toggleIndex + 1) % numStates;
    setState(options[nextIndex]);
  }, [state, numStates]);

  const getKnobPosition = () => {
    // the 0 should change to option[0] as default
    if (numStates === 2) {
      return state === options[0]
        ? { left: '5px', color: '#000' }
        : { left: 'calc(100% - 85px)', color: '#000' };
    }

    // cases changed to each options[index]
    switch (state) {
      case options[0]:
        return { left: '5px', color: '#000' };
      case options[1]:
        return {
          left: 'calc(50% - 40px)',
        };
      case options[2]:
        return {
          left: 'calc(100% - 85px)',
        };
      default:
        return { left: '5px', color: '#000' };
    }
  };

  const switchStyle: React.CSSProperties = {
    width: numStates === 3 ? '280px' : '180px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ddd',
    borderRadius: '20px',
    position: 'relative',
    cursor: 'pointer',
    padding: '5px 15px 5px 20px',
    boxSizing: 'border-box',
  };

  const knobStyle: React.CSSProperties = {
    width: '80px',
    height: '20px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    position: 'absolute',
    transition: 'left 0.2s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...getKnobPosition(),
  };

  const getKnobText = useCallback(() => {
    const result = options.find((v) => v === state);
    return result;
  }, [state]);

  const textStyle: React.CSSProperties = {
    fontSize: '1rem',
    color: '#000',
  };

  return (
    <div style={switchStyle} onClick={handleToggle}>
      {options.map((v, i) => (
        <div key={i}>{v}</div>
      ))}
      <div style={knobStyle}>
        <span style={textStyle}>{getKnobText()}</span>
      </div>
    </div>
  );
};

export default SwitchThreeWayToggle;
