/* eslint-disable no-nested-ternary */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Control, FieldError, useFieldArray } from 'react-hook-form';
import {
  ATMButton,
  ATMModal,
  ATMGrid,
  ATMTable,
  ATMField,
  ATMDropdown,
  ATMInput,
  ATMCheckbox,
  useATMFormContext,
  ATMForm,
  ATMFormProvider,
  ATMTimePicker,
  ATMDatePicker,
  ATMLabel,
  formatTime,
  ATMPopover,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useLerRequestContext } from 'src/contexts/ler-request.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useSwitchingContext } from 'src/contexts/switching.context';
import { lerRequestActionTypes } from 'src/ducks/ler-request.duck';
import Lang from 'src/libraries/language';
import Moment, {
  createDateTime,
  format24hTime,
  formatDate,
} from 'src/libraries/moment.library';
import {
  ILerRequest,
  ILerRequestEtsFormDispatchForm,
  ILerRequestForm,
  LerRequestEtsFormDispatchFormSchema,
} from 'src/models/ler-request.model';
import { IRealTimeLogLine } from 'src/models/real-time-log.model';
import { ISubstation } from 'src/models/substation.model';
import { ISortResponse, ISwitching } from 'src/models/switching.model';
import { getLerRequestStatus } from 'src/selectors/ler-request.selector';
import { debounce, orderBy } from 'lodash';
import { getSwitchingStatus } from 'src/selectors/switching.selector';
import { switchingActionTypes } from 'src/ducks/switching.duck';
import {
  HourTimePattern,
  restrictAlphabetsAndSpecialChars,
} from 'src/components/atoms/input/time-input-switching-format.component';
import { AssertsShape } from 'yup/lib/object';
import { PopupContentProps, SemanticShorthandItem } from 'semantic-ui-react';
import styles from './ler-request-switching-ets.module.scss';

type IContentProps = {
  defaultValues: ILerRequest;
  handleEnable: (value: boolean) => void;
};

type IContentPropsByDate = {
  defaultValues: ILerRequest;
  handleEnable: (value: boolean) => void;
  withDates: boolean;
};

const FormContent: React.FC<IContentProps> = ({
  defaultValues,
  handleEnable,
}) => {
  const {
    control,
    formState: { errors },
  } = useATMFormContext<ILerRequestEtsFormDispatchForm>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'etsDispatch',
  });
  const { state: realTimeLogState } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: switchingState } = useSwitchingContext();
  const switchStatus = getSwitchingStatus(
    switchingState,
    switchingActionTypes.SWITCHING_LIST_READ
  );

  const [lineSubstationSelect, setLineSubstationSelect] = useState<string>('');
  const [etsId, setEtsId] = useState<string>('');

  useEffect(() => {
    handleEnable(fields.some((val) => val.isChecked));
  }, [fields, handleEnable]);

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  const lineSubstationOptions = [
    {
      key: 1,
      text: 'Switch',
      value: 'switch',
    },
    {
      key: 2,
      text: 'Tie Line',
      value: 'tieLine',
    },
    {
      key: 3,
      text: 'Substation',
      value: 'substation',
    },
  ];

  const switchOptions = switchingState.listDropdown.map((val, i) => {
    return {
      key: `${val.description}_${i}`,
      text: val.description,
      value: val.description,
    };
  });

  const lineOptions = realTimeLogState.line
    .filter((val) => val.outgFacNm.includes('TL'))
    .map((val, i) => {
      return {
        key: `${val.outgFacNm}_${i}`,
        text: val.outgFacNm,
        value: val.outgFacId,
      };
    });

  const substationOptions = substationState.list.map((val, i) => {
    return {
      key: `${val.substationId}_${i}`,
      text: val.name,
      value: val.substationId,
    };
  });

  const handleAddTieLine = useCallback(
    (id: number) => {
      const facilityInfo: IRealTimeLogLine[] = realTimeLogState.line?.filter(
        (val) => val.outgFacId === id
      );
      if (facilityInfo && facilityInfo.length > 0) {
        const facilityData = facilityInfo[0].substations?.map(
          (val: ISubstation) => {
            return {
              tlSub: `${
                facilityInfo[0].outgFacNm.includes('(')
                  ? facilityInfo[0].outgFacNm.split('(')[0].replace(' ', '')
                  : facilityInfo[0].outgFacNm.split('at')[0].replace(' ', '')
              } ${val.substationId}`.replace(/\s\s+/g, ' '),
              subPole: val.name,
              subDistId: val.subDistId ?? '',
              outgFacId: id,
              poleId: null,
              substationId: val.substationId,
              etmNbr: 1,
              out: {
                byAt: 'AT',
                sortTime: '' as any,
              },
              back: {
                byAt: 'AT',
                sortTime: '' as any,
              },
              switchId: null,
              isChecked: false,
              backDate: undefined,
              backTime: undefined,
              outDate: undefined,
              outTime: undefined,
              selectedDates: [],
            };
          }
        );
        if (facilityData && facilityData.length > 0) {
          append(facilityData);
        }
      }
    },
    [append, control, etsId]
  );

  const handleAddSwitch = useCallback(
    (id: string) => {
      const switchInfo: ISwitching[] = switchingState.list?.filter(
        (val) => val.description === id
      );
      if (switchInfo && switchInfo.length > 0) {
        const switchData = switchInfo.map((val: ISwitching) => {
          return {
            tlSub: `${val.description}`,
            subPole: `POLE${val.poleId}`,
            subDistId: val.subDistId ?? '',
            outgFacId: val.outgFacId,
            poleId: val.poleId,
            substationId: null,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            back: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            switchId: val.switchId?.toString(),
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
            selectedDates: [],
          };
        });
        if (switchData && switchData.length > 0) {
          append(switchData);
        }
      }
    },
    [append, control, etsId]
  );

  const handleAddSubstation = useCallback(
    (id: string) => {
      const substationInfo: ISubstation[] = substationState.list?.filter(
        (val) => val.substationId === id
      );
      if (substationInfo && substationInfo.length > 0) {
        const substationData = substationInfo.map((val: ISubstation) => {
          return {
            tlSub: `${val.substationId}`,
            subPole: val.name,
            subDistId: val.subDistId ?? '',
            outgFacId: null,
            poleId: null,
            substationId: id,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            back: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            switchId: null,
            isChecked: false,
            backDate: undefined,
            backTime: undefined,
            outDate: undefined,
            outTime: undefined,
            selectedDates: [],
          };
        });
        if (substationData && substationData.length > 0) {
          append(substationData);
        }
      }
    },
    [append, control, etsId]
  );

  // Group sort responses by type[Tie Line, Substation, Switch]
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // eslint-disable-next-line no-param-reassign
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  useEffect(() => {
    remove();
    setLineSubstationSelect('');
    setEtsId('');

    // Setting default from selected line/station
    if (defaultValues.outageFacility.outgFacId) {
      handleAddTieLine(defaultValues.outageFacility.outgFacId);
    }
    if (defaultValues.outageFacility.substationId) {
      handleAddSubstation(defaultValues.outageFacility.substationId);
    }

    // Setting default from already save switching request
    if (defaultValues.sortResponses) {
      const sortGroupBy = Object.entries(
        groupBy(defaultValues.sortResponses, 'tlSub')
      ).map(([_, val]) => val) as ISortResponse[];
      sortGroupBy.map((val) => {
        let exists = false;
        if (val) {
          if (val[0].outgFacId && val[0].substationId) {
            exists = fields.some(
              (field) =>
                field.outgFacId === defaultValues.outageFacility.outgFacId
            );
            return !exists ? handleAddTieLine(val[0].outgFacId.toString()) : '';
          }
          if (val[0].substationId && val[0].outgFacId === null) {
            exists = fields.some(
              (field) =>
                field.substationId ===
                  defaultValues.outageFacility.substationId &&
                field.outgFacId === null
            );
            return !exists ? handleAddSubstation(val[0].substationId) : '';
          }
          return handleAddSwitch(val[0].tlSub);
        }
        return '';
      });
    }
  }, []);

  return (
    <ATMGrid>
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
            {defaultValues.outageFacility.substationId
              ? `${Lang.FACILITY_TYPE[2]} - ${
                  defaultValues.outageFacility.substation?.name ??
                  defaultValues.outageFacility.substationId
                }`
              : `${Lang.FACILITY_TYPE[1]} - ${defaultValues.outageFacility.facility?.outgFacNm}`}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <FieldReadOnly label={Lang.LBL_KV}>
            {defaultValues.outageFacility?.voltage?.voltNm ??
              defaultValues.outageFacility?.voltId}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <FieldReadOnly label={Lang.LBL_EQUIPMENT}>
            {defaultValues.outageFacility.substationId
              ? defaultValues.outageFacility?.facility?.outgFacNm ??
                defaultValues.outageFacility.outgFacId
              : ''}
          </FieldReadOnly>
        </ATMGrid.Column>
      </ATMGrid.Row>

      <ATMGrid.Row style={{ background: '#f1f2f3' }}>
        <ATMGrid.Column width={3}>
          <ATMField
            as={ATMDropdown}
            selection
            size="small"
            name="lineSubstation"
            defaultValues={lineSubstationSelect}
            options={lineSubstationOptions}
            onChange={
              ((_, { value }) => {
                if (!value) {
                  setEtsId('');
                  setLineSubstationSelect('');
                } else {
                  setLineSubstationSelect(value);
                }
                return value;
              }) as any
            }
            selectOnBlur={false}
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={3}>
          {lineSubstationSelect === 'switch' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              options={switchOptions}
              loading={switchStatus.fetching}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              disabled={!lineSubstationSelect}
              search
            />
          )}
          {lineSubstationSelect === 'tieLine' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              options={lineOptions}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              disabled={!lineSubstationSelect}
              search
            />
          )}{' '}
          {lineSubstationSelect === 'substation' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              options={substationOptions}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              search
            />
          )}
          {lineSubstationSelect === '' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              disabled
            />
          )}
        </ATMGrid.Column>
        <ATMGrid.Column width={2}>
          <ATMButton
            type="button"
            content="Add"
            secondary
            disabled={!lineSubstationSelect || !etsId}
            onClick={() => {
              if (lineSubstationSelect === 'tieLine') {
                handleAddTieLine(Number(etsId));
              } else if (lineSubstationSelect === 'switch') {
                handleAddSwitch(etsId);
              } else if (lineSubstationSelect === 'substation') {
                handleAddSubstation(etsId);
              }
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMTable celled>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell content={' '} />
              <ATMTable.HeaderCell content={Lang.LBL_ETS} />
              <ATMTable.HeaderCell content={Lang.LBL_OUT} />
              <ATMTable.HeaderCell content={Lang.LBL_DATE_TIME} />
              <ATMTable.HeaderCell content={Lang.LBL_BACK} />
              <ATMTable.HeaderCell content={Lang.LBL_DATE_TIME} />
              <ATMTable.HeaderCell content={Lang.LBL_TL_SUB} />
              <ATMTable.HeaderCell content={Lang.LBL_SUB_POLE} />
              <ATMTable.HeaderCell content={Lang.LBL_AREA} />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((val, i) => (
              <ATMTable.Row key={val.id}>
                {/* hidden */}
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].tlSub`}
                  defaultValue={val.tlSub}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].subPole`}
                  defaultValue={val.subPole}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].subDistId`}
                  defaultValue={val.subDistId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].outgFacId`}
                  defaultValue={val.outgFacId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].switchId`}
                  defaultValue={val.switchId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].substationId`}
                  defaultValue={val.substationId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].poleId`}
                  defaultValue={val.poleId}
                  type="hidden"
                  className="hidden"
                />
                <ATMTable.Cell className={styles.etsInput}>
                  <ATMField
                    as={ATMCheckbox}
                    name={`etsDispatch[${i}].isChecked`}
                    control={control}
                    defaultValue={val.isChecked}
                    onChange={([_, { checked }]) => {
                      update(i, {
                        ...val,
                        isChecked: checked,
                      });
                      return checked;
                    }}
                    className={
                      !val.subDistId || !val.tlSub || !val.subPole
                        ? 'hidden'
                        : ''
                    }
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.etsInput}>
                  <ATMField
                    as={ATMInput}
                    name={`etsDispatch[${i}].etmNbr`}
                    control={control}
                    defaultValue={val.etmNbr}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.outDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name={`etsDispatch[${i}].out.byAt`}
                      onChange={([_, { value }]) => value}
                      options={[
                        { key: 'AT', value: 'AT', text: 'AT' },
                        { key: 'BY', value: 'BY', text: 'BY' },
                      ]}
                      control={control}
                      defaultValue={val.out.byAt}
                      error={errors.etsDispatch?.[i]?.out?.byAt}
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.outDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMTimePicker}
                      size="small"
                      is24hourformat
                      control={control}
                      name={`etsDispatch[${i}].out.sortTime`}
                      defaultValue={format24hTime(val.out.sortTime)}
                      error={errors.etsDispatch?.[i]?.out?.sortTime}
                      onChange={([_, { value }]) => {
                        if (value) {
                          const [hour, minute] = value.split(':');
                          return Moment().set({
                            hour,
                            minute,
                          });
                        }

                        return value;
                      }}
                      disabled
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.backDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name={`etsDispatch[${i}].back.byAt`}
                      options={[
                        { key: 'AT', value: 'AT', text: 'AT' },
                        { key: 'BY', value: 'BY', text: 'BY' },
                      ]}
                      onChange={([_, { value }]) => value}
                      control={control}
                      defaultValue={val.back.byAt ?? 'AT'}
                      error={errors.etsDispatch?.[i]?.back?.byAt}
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.backDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMTimePicker}
                      size="small"
                      is24hourformat
                      control={control}
                      name={`etsDispatch[${i}].back.sortTime`}
                      defaultValue={format24hTime(val.back.sortTime)}
                      error={errors.etsDispatch?.[i]?.back?.sortTime}
                      onChange={([_, { value }]) => {
                        if (value) {
                          const [hour, minute] = value.split(':');
                          return Moment().set({
                            hour,
                            minute,
                          });
                        }

                        return value;
                      }}
                      disabled
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell>{val.tlSub}</ATMTable.Cell>
                <ATMTable.Cell>{val.subPole}</ATMTable.Cell>
                <ATMTable.Cell>{val.subDistId}</ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
        </ATMTable>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

type IPropsType = {
  inputRef: React.RefObject<HTMLInputElement>;
  withDates: boolean;
  control: Control<AssertsShape<any>>;
  value: string | null | undefined;
  i: number;
  name: string;
  val: Record<'id', string>[] | any;
  error: FieldError | undefined;
  onChange: ([_, { value }]: [any, { value: any }]) => any;
  // popover
  content: SemanticShorthandItem<PopupContentProps>;
  onChangePopover: ([_, { value }]: [any, { value: any }]) => any;
};

const SwitchingTimeInput: React.FC<IPropsType> = ({
  inputRef,
  withDates,
  control,
  value,
  i,
  name,
  val,
  error,
  onChange,
  content,
  onChangePopover,
}) => {
  const disableLetterType = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div>
      {val.selectedDates && !val?.selectedDates[0]?.date ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ATMField
            as={ATMInput}
            ref={inputRef}
            className={name === 'outTime' ? styles.outTime : styles.backTime}
            disabled={!withDates}
            size="small"
            onKeyPress={disableLetterType}
            clearable
            maxLength={5}
            control={control}
            placeholder="hh:mm"
            name={`etsDispatch[${i}].${name}`}
            value={value}
            error={error}
            onChange={onChange}
          />
        </div>
      ) : (
        <ATMPopover
          content={content}
          size="mini"
          trigger={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ATMField
                as={ATMInput}
                size="small"
                maxLength={5}
                clearable
                ref={inputRef}
                onKeyPress={disableLetterType}
                placeholder="hh:mm"
                control={control}
                name={`etsDispatch[${i}].${name}`}
                value={value}
                error={error}
                onChange={onChangePopover}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

const FormContentByDates: React.FC<IContentPropsByDate> = ({
  defaultValues,
  handleEnable,
  withDates,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
  } = useATMFormContext<ILerRequestEtsFormDispatchForm>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'etsDispatch',
  });
  const { state: realTimeLogState } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: switchingState } = useSwitchingContext();
  const switchStatus = getSwitchingStatus(
    switchingState,
    switchingActionTypes.SWITCHING_LIST_READ
  );

  const [lineSubstationSelect, setLineSubstationSelect] = useState<string>('');
  const [isStartTmError, setIsStartTmError] = useState<boolean>(false);
  const [isStopTmError, setIsStopTmError] = useState<boolean>(false);
  const [etsId, setEtsId] = useState<string>('');
  const [startTmInput, setStartTmInput] = useState<string>('07:00');
  const [stopTmInput, setStopTmInput] = useState<string>('15:00');
  const [sameSelectedDate, setSameSelectedDate] = useState<Date | string>();
  const [hasErrTblInputTm, setHasErrTblInputTm] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<
    | {
        date: Date;
        startTm: Date;
        stopTm: Date;
      }[]
    | []
  >([]);
  const [hasGreaterTmErr, setHasGreaterTmErr] = useState<boolean>(false);
  const [hasLessTmErr, setHasLessTmErr] = useState<boolean>(false);

  useEffect(() => {
    let isEnabledButton = false;
    if (!withDates) {
      isEnabledButton = fields.some((val) => val?.isChecked);
    } else {
      isEnabledButton =
        selectedDates.length !== 0 && fields.some((val) => val?.isChecked);
    }
    handleEnable(isEnabledButton);
  }, [fields, handleEnable]);

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  const lineSubstationOptions = [
    {
      key: 1,
      text: 'Switch',
      value: 'switch',
    },
    {
      key: 2,
      text: 'Tie Line',
      value: 'tieLine',
    },
    {
      key: 3,
      text: 'Substation',
      value: 'substation',
    },
  ];

  const switchOptions = switchingState.listDropdown.map((val, i) => {
    return {
      key: `${val.description}_${i}`,
      text: val.description,
      value: val.description,
    };
  });

  const lineOptions = realTimeLogState.line
    .filter((val) => val.outgFacNm.includes('TL'))
    .map((val, i) => {
      return {
        key: `${val.outgFacNm}_${i}`,
        text: val.outgFacNm,
        value: val.outgFacId,
      };
    });

  const substationOptions = substationState.list.map((val, i) => {
    return {
      key: `${val.substationId}_${i}`,
      text: val.name,
      value: val.substationId,
    };
  });

  const handleAddTieLine = useCallback(
    (id: number) => {
      const facilityInfo: IRealTimeLogLine[] = realTimeLogState.line?.filter(
        (val) => val.outgFacId === id
      );
      if (facilityInfo && facilityInfo.length > 0) {
        const facilityData = facilityInfo[0].substations?.map(
          (val: ISubstation) => {
            return {
              tlSub: `${
                facilityInfo[0].outgFacNm.includes('(')
                  ? facilityInfo[0].outgFacNm.split('(')[0].replace(' ', '')
                  : facilityInfo[0].outgFacNm.split('at')[0].replace(' ', '')
              } ${val.substationId}`.replace(/\s\s+/g, ' '),
              subPole: val.name,
              subDistId: val.subDistId ?? '',
              outgFacId: id,
              poleId: null,
              substationId: val.substationId,
              etmNbr: 1,
              out: {
                byAt: 'AT',
                sortTime: '' as any,
              },
              back: {
                byAt: 'AT',
                sortTime: '' as any,
              },
              switchId: null,
              isChecked: false,
              outDate: selectedDates?.length ? selectedDates[0]?.date : null,
              outTime: selectedDates?.length
                ? format24hTime(selectedDates[0]?.startTm)
                : null,
              backDate: selectedDates?.length ? selectedDates[0]?.date : null,
              backTime: selectedDates?.length
                ? format24hTime(selectedDates[0]?.stopTm)
                : null,
              selectedDates: selectedDates ?? [],
            };
          }
        );
        if (facilityData && facilityData.length > 0) {
          append(facilityData);
        }
      }
    },
    [append, control, etsId, selectedDates]
  );

  const handleAddSwitch = useCallback(
    (id: string) => {
      const switchInfo: ISwitching[] = switchingState.list?.filter(
        (val) => val.description === id
      );
      if (switchInfo && switchInfo.length > 0) {
        const switchData = switchInfo.map((val: ISwitching) => {
          return {
            tlSub: `${val.description}`,
            subPole: `POLE${val.poleId}`,
            subDistId: val.subDistId ?? '',
            outgFacId: val.outgFacId,
            poleId: val.poleId,
            substationId: null,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            back: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            switchId: val.switchId.toString(),
            isChecked: false,
            outDate: selectedDates?.length ? selectedDates[0]?.date : null,
            outTime: selectedDates?.length
              ? format24hTime(selectedDates[0]?.startTm)
              : null,
            backDate: selectedDates?.length ? selectedDates[0]?.date : null,
            backTime: selectedDates?.length
              ? format24hTime(selectedDates[0]?.stopTm)
              : null,
            selectedDates: selectedDates ?? [],
          };
        });
        if (switchData && switchData.length > 0) {
          append(switchData);
        }
      }
    },
    [append, control, etsId, selectedDates]
  );

  const handleAddSubstation = useCallback(
    (id: string) => {
      const substationInfo: ISubstation[] = substationState.list?.filter(
        (val) => val.substationId === id
      );
      if (substationInfo && substationInfo.length > 0) {
        const substationData = substationInfo.map((val: ISubstation) => {
          return {
            tlSub: `${val.substationId}`,
            subPole: val.name,
            subDistId: val.subDistId ?? '',
            outgFacId: null,
            poleId: null,
            substationId: id,
            etmNbr: 1,
            out: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            back: {
              byAt: 'AT',
              sortTime: '' as any,
            },
            switchId: null,
            isChecked: false,
            outDate: selectedDates?.length ? selectedDates[0]?.date : null,
            outTime: selectedDates?.length
              ? format24hTime(selectedDates[0]?.startTm)
              : null,
            backDate: selectedDates?.length ? selectedDates[0]?.date : null,
            backTime: selectedDates?.length
              ? format24hTime(selectedDates[0]?.stopTm)
              : null,
            selectedDates: selectedDates ?? [],
          };
        });
        if (substationData && substationData.length > 0) {
          append(substationData);
        }
      }
    },
    [append, control, etsId, selectedDates]
  );

  // Group sort responses by type[Tie Line, Substation, Switch]
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      // eslint-disable-next-line no-param-reassign
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  useEffect(() => {
    remove();
    setLineSubstationSelect('');
    setEtsId('');

    // Setting default from selected line/station
    if (defaultValues.outageFacility.outgFacId) {
      handleAddTieLine(defaultValues.outageFacility.outgFacId);
    }
    if (defaultValues.outageFacility.substationId) {
      handleAddSubstation(defaultValues.outageFacility.substationId);
    }

    // Setting default from already save switching request
    if (defaultValues.sortResponses) {
      const sortGroupBy = Object.entries(
        groupBy(defaultValues.sortResponses, 'tlSub')
      ).map(([_, val]) => val) as ISortResponse[];
      sortGroupBy.map((val) => {
        let exists = false;
        if (val) {
          if (val[0].outgFacId && val[0].substationId) {
            exists = fields.some(
              (field) =>
                field.outgFacId === defaultValues.outageFacility.outgFacId
            );
            return !exists ? handleAddTieLine(val[0].outgFacId.toString()) : '';
          }
          if (val[0].substationId && val[0].outgFacId === null) {
            exists = fields.some(
              (field) =>
                field.substationId ===
                  defaultValues.outageFacility.substationId &&
                field.outgFacId === null
            );
            return !exists ? handleAddSubstation(val[0].substationId) : '';
          }
          return handleAddSwitch(val[0].tlSub);
        }
        return '';
      });
    }
  }, []);

  const handleStartTmChange = useCallback(
    (timeValue: any) => {
      setStartTmInput(timeValue ?? '');

      if (!timeValue.match(HourTimePattern.HOUR_TIME_PATTERN)) {
        setIsStartTmError(true);
      }

      if (
        timeValue.match(HourTimePattern.HOUR_TIME_PATTERN) &&
        timeValue.length > 4
      ) {
        setIsStartTmError(false);

        let sdIndex;
        let sdToUpdateTm;
        if (sameSelectedDate) {
          const [filtered] = selectedDates.filter((v) =>
            Moment(v.date).isSame(sameSelectedDate as Date)
          );
          sdToUpdateTm = filtered;
          const findIndex = selectedDates.findIndex((v) =>
            Moment(v.date).isSame(sameSelectedDate as Date)
          );
          sdIndex = findIndex !== -1 ? findIndex : 0;
        } else {
          sdIndex = selectedDates.length - 1;
          sdToUpdateTm = selectedDates[sdIndex];
        }

        const format = 'MM/DD/YYYY HH:mm';
        const startDateTm = createDateTime(
          Moment(sdToUpdateTm.startTm as unknown as any),
          timeValue
        ).format(format);
        const stopDateTm = Moment(sdToUpdateTm.stopTm).format(format);
        if (sdToUpdateTm.stopTm) {
          if (
            Moment(stopDateTm).isBefore(Moment(startDateTm)) ||
            Moment(stopDateTm).isSame(Moment(startDateTm))
          ) {
            setHasLessTmErr(true);
          } else {
            setIsStopTmError(false);
            setIsStartTmError(false);
            setHasGreaterTmErr(false);
            setHasLessTmErr(false);
          }
        }

        const updatedDates = [...selectedDates];
        updatedDates[sdIndex] = {
          ...sdToUpdateTm,
          startTm: createDateTime(
            Moment(sdToUpdateTm.startTm as unknown as any),
            timeValue
          ).toDate(),
        };
        setSelectedDates(updatedDates);
      }
    },
    [
      selectedDates,
      setStartTmInput,
      setIsStartTmError,
      setSelectedDates,
      setIsStopTmError,
      setHasLessTmErr,
    ]
  );

  const debouncedHandleStartTmChange = useCallback(
    debounce((value: any) => {
      handleStartTmChange(value);
    }, 10),
    [handleStartTmChange]
  );

  const handleStopTmChange = useCallback(
    (timeValue: any) => {
      setStopTmInput(timeValue ?? '');

      if (!timeValue.match(HourTimePattern.HOUR_TIME_PATTERN)) {
        setIsStopTmError(true);
      }

      if (
        timeValue.match(HourTimePattern.HOUR_TIME_PATTERN) &&
        timeValue.length > 4
      ) {
        setIsStopTmError(false);

        let sdIndex: any;
        let sdToUpdateTm: any;
        if (sameSelectedDate) {
          const [filtered] = selectedDates.filter((v) =>
            Moment(v.date).isSame(sameSelectedDate as Date)
          );
          sdToUpdateTm = filtered;
          const findIndex = selectedDates.findIndex((v) =>
            Moment(v.date).isSame(sameSelectedDate as Date)
          );
          sdIndex = findIndex !== -1 ? findIndex : 0;
        } else {
          sdIndex = selectedDates.length - 1;
          sdToUpdateTm = selectedDates[sdIndex];
        }

        // check if stopTm is not before startTm
        const format = 'MM/DD/YYYY HH:mm';
        const stopDateTm = createDateTime(
          Moment(sdToUpdateTm.date as unknown as any),
          timeValue
        ).format(format);
        const startDateTm = Moment(sdToUpdateTm.startTm).format(format);
        if (
          Moment(stopDateTm).isBefore(Moment(startDateTm)) ||
          Moment(stopDateTm).isSame(Moment(startDateTm))
        ) {
          setHasGreaterTmErr(true);
        } else {
          setIsStopTmError(false);
          setHasGreaterTmErr(false);
          setHasLessTmErr(false);

          const updatedDates = [...selectedDates];
          updatedDates[sdIndex] = {
            ...sdToUpdateTm,
            stopTm: createDateTime(
              Moment(sdToUpdateTm.date as unknown as any),
              timeValue
            ).toDate(),
          };
          setSelectedDates(updatedDates);
        }
      }
    },
    [
      selectedDates,
      setIsStopTmError,
      setSelectedDates,
      setHasGreaterTmErr,
      setHasLessTmErr,
    ]
  );

  const debounceHandleStopTmChange = useCallback(
    debounce((value: any) => {
      handleStopTmChange(value);
    }, 10),
    [handleStopTmChange]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleTimeUpdate = useCallback(
    debounce((value, name) => {
      const input = document.getElementsByName(name)[0] as HTMLInputElement;
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(input, value);
      const ocEvent = new Event('input', { bubbles: true });
      input?.dispatchEvent(ocEvent);

      if (input && value) {
        input.setSelectionRange(value.length, value.length);
      }
      input?.focus();
    }, 50),
    []
  );

  const disableLetterType = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const clearErrFunc = (
    startDateTm: Date | string,
    endDateTm: any,
    i: string | number
  ) => {
    const getEndTime = endDateTm.split(' ')[1];
    if (
      Moment(endDateTm).isAfter(
        Moment(startDateTm) || !Moment(startDateTm).isSame(Moment(endDateTm))
      )
    ) {
      if (getEndTime.match(HourTimePattern.HOUR_TIME_PATTERN)) {
        clearErrors(`etsDispatch[${i}].backTime` as any);
      }
      clearErrors(`etsDispatch[${i}].outTime` as any);
    }
  };

  return (
    <ATMGrid>
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <FieldReadOnly label={Lang.LBL_LINE_SUBSTATION}>
            {defaultValues.outageFacility.substationId
              ? `${Lang.FACILITY_TYPE[2]} - ${
                  defaultValues.outageFacility.substation?.name ??
                  defaultValues.outageFacility.substationId
                }`
              : `${Lang.FACILITY_TYPE[1]} - ${defaultValues.outageFacility.facility?.outgFacNm}`}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <FieldReadOnly label={Lang.LBL_KV}>
            {defaultValues.outageFacility?.voltage?.voltNm ??
              defaultValues.outageFacility?.voltId}
          </FieldReadOnly>
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <FieldReadOnly label={Lang.LBL_EQUIPMENT}>
            {defaultValues.outageFacility.substationId
              ? defaultValues.outageFacility?.facility?.outgFacNm ??
                defaultValues.outageFacility.outgFacId
              : ''}
          </FieldReadOnly>
        </ATMGrid.Column>
      </ATMGrid.Row>
      {withDates && (
        <ATMGrid.Row style={{ background: '#f1f2f3', display: 'flex' }}>
          <ATMGrid.Column width={4}>
            <span>
              <ATMDatePicker
                clearOnSameDateClick={false}
                className={styles.inputDatePicker}
                label="Date"
                name="datePicker"
                format="MM-DD-YYYY"
                minDate={Moment(new Date()).startOf('day').toDate()}
                onChange={(_, { value }) => {
                  if (value) {
                    setHasErrTblInputTm(false);

                    const sameSelectDate = selectedDates.filter((v) =>
                      Moment(v.date).isSame(value as Date)
                    )[0];
                    if (sameSelectDate) {
                      setSameSelectedDate(value as Date);
                      setStartTmInput(
                        Moment(sameSelectDate.startTm)
                          .format('HH:mm')
                          .toString()
                      );
                      setStopTmInput(
                        Moment(sameSelectDate.stopTm).format('HH:mm').toString()
                      );
                    } else {
                      const select = formatDate(value as Date);
                      if (select) {
                        const newDate = new Date(select);
                        const start = Moment(newDate).hour(7).toString();
                        const stop = Moment(newDate).hour(15).toString();
                        const setDate = {
                          date: newDate,
                          startTm: new Date(start),
                          stopTm: new Date(stop),
                        };
                        setImmediate(() => {
                          setSelectedDates([setDate]);
                          setStartTmInput('07:00');
                          setStopTmInput('15:00');
                        });
                      }
                    }
                  } else {
                    setSelectedDates([]);
                    setStartTmInput('07:00');
                    setStopTmInput('15:00');
                    setLineSubstationSelect('');
                  }
                  return value;
                }}
              />
            </span>
          </ATMGrid.Column>

          <ATMGrid.Column width={3}>
            <ATMGrid.Row style={{ display: 'flex' }}>
              <ATMGrid.Column className={styles.inputTime}>
                <ATMField
                  as={ATMInput}
                  label={Lang.LBL_SWITCHING_START_TIME}
                  maxLength={5}
                  clearable
                  name="startTm"
                  placeholder="hh:mm"
                  onKeyPress={disableLetterType}
                  error={isStartTmError || hasLessTmErr}
                  disabled={selectedDates.length === 0}
                  value={startTmInput}
                  defaultValue="07:00"
                  onChange={(_, { value }) => {
                    setIsStartTmError(true);
                    if (value) {
                      const timeValue =
                        value && value.length > 3
                          ? formatTime(restrictAlphabetsAndSpecialChars(value))
                          : restrictAlphabetsAndSpecialChars(value);
                      // setStartTmInput(timeValue);
                      if (timeValue.length === 5) {
                        debouncedHandleStartTmChange(timeValue);
                      }
                    } else {
                      setIsStartTmError(true);
                    }
                  }}
                />
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMInput}
                  label={Lang.LBL_SWITCHING_END_TIME}
                  maxLength={5}
                  clearable
                  placeholder="hh:mm"
                  className={styles.inputTime}
                  onKeyPress={disableLetterType}
                  name="stopTm"
                  error={isStopTmError || hasGreaterTmErr}
                  disabled={selectedDates.length === 0}
                  value={stopTmInput}
                  defaultValue="15:00"
                  onChange={(_, { value }) => {
                    setIsStopTmError(true);
                    if (value) {
                      const timeValue =
                        value && value.length > 3
                          ? formatTime(restrictAlphabetsAndSpecialChars(value))
                          : restrictAlphabetsAndSpecialChars(value);
                      // setStopTmInput(value);
                      if (timeValue.length === 5) {
                        debounceHandleStopTmChange(timeValue);
                      }
                    } else {
                      setIsStopTmError(true);
                    }
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid.Column>

          {(isStartTmError || isStopTmError) &&
          !hasErrTblInputTm &&
          !hasGreaterTmErr &&
          !hasLessTmErr ? (
            <ATMLabel
              style={{
                color: 'red',
                marginLeft: 28,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                marginTop: 18,
              }}
              size="medium"
            >
              Start Time and/or End Time is invalid
            </ATMLabel>
          ) : hasErrTblInputTm ? (
            <ATMLabel
              style={{
                color: 'red',
                marginLeft: 28,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                marginTop: 18,
              }}
              size="medium"
            >
              Please select date
            </ATMLabel>
          ) : hasGreaterTmErr ? (
            <ATMLabel
              style={{
                color: 'red',
                marginLeft: 28,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                marginTop: 18,
              }}
              size="medium"
            >
              Must be greater than the start time
            </ATMLabel>
          ) : hasLessTmErr ? (
            <ATMLabel
              style={{
                color: 'red',
                marginLeft: 28,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                marginTop: 18,
              }}
              size="medium"
            >
              Must be less than the end time
            </ATMLabel>
          ) : null}
        </ATMGrid.Row>
      )}

      <ATMGrid.Row style={{ background: '#f1f2f3', marginTop: '-12px' }}>
        <ATMGrid.Column width={4}>
          <ATMField
            as={ATMDropdown}
            selection
            placeholder="Please select"
            size="small"
            name="lineSubstation"
            value={lineSubstationSelect}
            options={lineSubstationOptions}
            disabled={withDates && selectedDates.length === 0}
            onChange={
              ((_, { value }) => {
                if (!value) {
                  setEtsId('');
                  setLineSubstationSelect('');
                } else {
                  setLineSubstationSelect(value);
                }
                return value;
              }) as any
            }
            selectOnBlur={false}
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={3}>
          {lineSubstationSelect === 'switch' && (
            <ATMField
              as={ATMDropdown}
              selection
              placeholder="Please select"
              size="small"
              search
              name="lineSubstation"
              options={switchOptions}
              loading={switchStatus.fetching}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              disabled={!lineSubstationSelect}
            />
          )}
          {lineSubstationSelect === 'tieLine' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              search
              placeholder="Please select"
              name="lineSubstation"
              options={lineOptions}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
              disabled={!lineSubstationSelect}
            />
          )}{' '}
          {lineSubstationSelect === 'substation' && (
            <ATMField
              as={ATMDropdown}
              selection
              size="small"
              search
              placeholder="Please select"
              name="lineSubstation"
              options={substationOptions}
              onChange={
                ((_, { value }) => {
                  if (value) {
                    setEtsId(value);
                  } else setEtsId('');
                  return value;
                }) as any
              }
              clearable
              selectOnBlur={false}
            />
          )}
          {lineSubstationSelect === '' && (
            <ATMField
              className={styles.lineSubOptions}
              as={ATMDropdown}
              selection
              size="small"
              name="lineSubstation"
              disabled
            />
          )}
        </ATMGrid.Column>
        <ATMGrid.Column width={2} style={{ marginLeft: 13 }}>
          <ATMButton
            type="button"
            content="Add"
            secondary
            disabled={
              (withDates && selectedDates.length === 0) ||
              isStartTmError ||
              isStopTmError ||
              !lineSubstationSelect ||
              !etsId
            }
            onClick={() => {
              if (lineSubstationSelect === 'tieLine') {
                handleAddTieLine(Number(etsId));
              } else if (lineSubstationSelect === 'switch') {
                handleAddSwitch(etsId);
              } else if (lineSubstationSelect === 'substation') {
                handleAddSubstation(etsId);
              }
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMTable celled>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell content={' '} />
              <ATMTable.HeaderCell content={Lang.LBL_ETS} />
              <ATMTable.HeaderCell content={Lang.LBL_OUT} />
              <ATMTable.HeaderCell content={Lang.LBL_SWITCHING_START_TIME} />
              <ATMTable.HeaderCell content={Lang.LBL_BACK} />
              <ATMTable.HeaderCell content={Lang.LBL_SWITCHING_END_TIME} />
              <ATMTable.HeaderCell content={Lang.LBL_TL_SUB} />
              <ATMTable.HeaderCell content={Lang.LBL_SUB_POLE} />
              <ATMTable.HeaderCell content={Lang.LBL_AREA} />
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {fields.map((val, i) => (
              <ATMTable.Row key={val.id}>
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].tlSub`}
                  defaultValue={val.tlSub}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].subPole`}
                  defaultValue={val.subPole}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].subDistId`}
                  defaultValue={val.subDistId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].outgFacId`}
                  defaultValue={val.outgFacId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].switchId`}
                  defaultValue={val.switchId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].substationId`}
                  defaultValue={val.substationId}
                  type="hidden"
                  className="hidden"
                />
                <ATMField
                  control={control}
                  as={ATMInput}
                  name={`etsDispatch[${i}].poleId`}
                  defaultValue={val.poleId}
                  type="hidden"
                  className="hidden"
                />
                <ATMTable.Cell className={styles.etsInput}>
                  <ATMField
                    as={ATMCheckbox}
                    name={`etsDispatch[${i}].isChecked`}
                    control={control}
                    defaultValue={val.isChecked}
                    onChange={([_, { checked }]) => {
                      update(i, {
                        ...val,
                        isChecked: checked,
                      });
                      return checked;
                    }}
                    className={
                      !val.subDistId || !val.tlSub || !val.subPole
                        ? 'hidden'
                        : ''
                    }
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.etsInput}>
                  <ATMField
                    as={ATMInput}
                    name={`etsDispatch[${i}].etmNbr`}
                    control={control}
                    defaultValue={val.etmNbr}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.outDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name={`etsDispatch[${i}].out.byAt`}
                      onChange={([_, { value }]) => value}
                      options={[
                        { key: 'AT', value: 'AT', text: 'AT' },
                        { key: 'BY', value: 'BY', text: 'BY' },
                      ]}
                      control={control}
                      defaultValue={val.out.byAt}
                      error={errors.etsDispatch?.[i]?.out?.byAt}
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.outTime}>
                  <SwitchingTimeInput
                    inputRef={inputRef}
                    withDates={withDates}
                    control={control}
                    value={getValues()?.etsDispatch?.[i]?.outTime ?? undefined}
                    i={i}
                    name="outTime"
                    error={errors.etsDispatch?.[i]?.outTime}
                    onChange={([_, { value }]) => {
                      const timeValue =
                        value && value.length > 3
                          ? formatTime(restrictAlphabetsAndSpecialChars(value))
                          : restrictAlphabetsAndSpecialChars(value);

                      if (val?.selectedDates?.length === 0) {
                        if (selectedDates.length === 0) {
                          setHasErrTblInputTm(true);
                          update(i, {
                            ...val,
                            outTime: timeValue,
                          });
                        } else if (
                          selectedDates.length > 0 &&
                          timeValue?.length > 4
                        ) {
                          clearErrors(`etsDispatch[${i}].outTime` as any);
                          setHasErrTblInputTm(false);
                          handleTimeUpdate(
                            timeValue,
                            `etsDispatch[${i}].outTime`
                          );

                          update(i, {
                            ...val,
                            outTime: timeValue,
                            backTime: null,
                            selectedDates: [
                              {
                                date: selectedDates[0].date,
                                startTm: createDateTime(
                                  Moment(
                                    selectedDates[0].date as unknown as any
                                  ),
                                  timeValue
                                ).toDate(),
                                stopTm: undefined,
                              },
                            ],
                          });
                        }
                      }
                      return timeValue;
                    }}
                    // popover details
                    content={
                      !val.selectedDates
                        ? null
                        : formatDate(val.selectedDates[0]?.date)
                    }
                    onChangePopover={([_, { value }]) => {
                      const timeValue =
                        value && value.length > 3
                          ? formatTime(restrictAlphabetsAndSpecialChars(value))
                          : restrictAlphabetsAndSpecialChars(value);
                      if (value) {
                        clearErrors(`etsDispatch[${i}].outTime` as any);
                        if (timeValue?.length > 4) {
                          handleTimeUpdate(
                            timeValue,
                            `etsDispatch[${i}].outTime`
                          );
                          if (
                            timeValue.match(
                              HourTimePattern.HOUR_TIME_PATTERN as any
                            )
                          ) {
                            handleTimeUpdate(
                              timeValue,
                              `etsDispatch[${i}].outTime`
                            );
                            if (val.selectedDates) {
                              if (val.selectedDates[0].stopTm !== null) {
                                const format = 'MM/DD/YYYY HH:mm';
                                const startDateTm = createDateTime(
                                  Moment(
                                    val.selectedDates[0].date as unknown as any
                                  ),
                                  timeValue
                                ).format(format);
                                const endDateTm = Moment(
                                  val.selectedDates[0].stopTm as Date
                                ).format(format);

                                clearErrFunc(startDateTm, endDateTm, i);
                              }
                              update(i, {
                                ...val,
                                outTime: timeValue,
                                selectedDates: [
                                  {
                                    ...val.selectedDates[0],
                                    startTm: createDateTime(
                                      Moment(
                                        val.selectedDates[0]
                                          .date as unknown as any
                                      ),
                                      timeValue
                                    ).toDate(),
                                  },
                                ],
                              });
                            }
                          } else {
                            update(i, {
                              ...val,
                              outTime: timeValue,
                            });
                          }
                        }
                      }
                      return timeValue;
                    }}
                    val={val}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.backDateTime}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ATMField
                      as={ATMDropdown}
                      selection
                      name={`etsDispatch[${i}].back.byAt`}
                      options={[
                        { key: 'AT', value: 'AT', text: 'AT' },
                        { key: 'BY', value: 'BY', text: 'BY' },
                      ]}
                      onChange={([_, { value }]) => value}
                      control={control}
                      defaultValue={val.back.byAt ?? 'AT'}
                      error={errors.etsDispatch?.[i]?.back?.byAt}
                    />
                  </div>
                </ATMTable.Cell>
                <ATMTable.Cell className={styles.backTime}>
                  <SwitchingTimeInput
                    inputRef={inputRef}
                    withDates={withDates}
                    control={control}
                    value={getValues()?.etsDispatch?.[i]?.backTime ?? undefined}
                    i={i}
                    name="backTime"
                    val={val}
                    error={errors.etsDispatch?.[i]?.backTime}
                    onChange={([_, { value }]) => {
                      const timeValue =
                        value && value.length > 3
                          ? formatTime(restrictAlphabetsAndSpecialChars(value))
                          : restrictAlphabetsAndSpecialChars(value);

                      if (val?.selectedDates?.length === 0) {
                        if (selectedDates.length === 0) {
                          setHasErrTblInputTm(true);
                          update(i, {
                            ...val,
                            backTime: timeValue,
                          });
                        } else if (
                          selectedDates.length > 0 &&
                          timeValue?.length > 4
                        ) {
                          setHasErrTblInputTm(false);
                          handleTimeUpdate(
                            timeValue,
                            `etsDispatch[${i}].backTime`
                          );

                          if (!val.selectedDates[0]) {
                            update(i, {
                              ...val,
                              backTime: timeValue,
                              outTime: null,
                              selectedDates: [
                                {
                                  date: selectedDates[0].date,
                                  stopTm: createDateTime(
                                    Moment(
                                      selectedDates[0].date as unknown as any
                                    ),
                                    timeValue
                                  ).toDate(),
                                  startTm: undefined,
                                },
                              ],
                            });
                          } else {
                            update(i, {
                              ...val,
                              backTime: timeValue,
                              selectedDates: [
                                {
                                  date: val.selectedDates[0].date,
                                  startTm: val.selectedDates[0].startTm,
                                  stopTm: createDateTime(
                                    Moment(
                                      selectedDates[0].date as unknown as any
                                    ),
                                    timeValue
                                  ).toDate(),
                                },
                              ],
                            });
                          }
                        }
                      }

                      return timeValue;
                    }}
                    // popover details
                    content={
                      !val.selectedDates
                        ? null
                        : formatDate(val.selectedDates[0]?.date)
                    }
                    onChangePopover={([_, { value }]) => {
                      const timeValue =
                        value && value.length > 3
                          ? formatTime(restrictAlphabetsAndSpecialChars(value))
                          : restrictAlphabetsAndSpecialChars(value);
                      if (value) {
                        clearErrors(`etsDispatch[${i}].backTime` as any);

                        if (timeValue?.length > 4) {
                          if (
                            timeValue.match(
                              HourTimePattern.HOUR_TIME_PATTERN as any
                            )
                          ) {
                            handleTimeUpdate(
                              timeValue,
                              `etsDispatch[${i}].backTime`
                            );
                            if (val.selectedDates) {
                              const format = 'MM/DD/YYYY HH:mm';
                              const startDateTm = Moment(
                                val.selectedDates[0].startTm as Date
                              ).format(format);
                              const endDateTm = createDateTime(
                                Moment(
                                  val.selectedDates[0].date as unknown as any
                                ),
                                timeValue
                              ).format(format);

                              clearErrFunc(startDateTm, endDateTm, i);
                              update(i, {
                                ...val,
                                backTime: timeValue,
                                selectedDates: [
                                  {
                                    date: val.selectedDates[0].date,
                                    startTm: val.selectedDates[0].startTm,
                                    stopTm: createDateTime(
                                      Moment(
                                        val.selectedDates[0]
                                          .date as unknown as any
                                      ),
                                      timeValue
                                    ).toDate(),
                                  },
                                ],
                              });
                            }
                          } else {
                            handleTimeUpdate(
                              timeValue,
                              `etsDispatch[${i}].backTime`
                            );
                            update(i, {
                              ...val,
                              backTime: timeValue,
                            });
                          }
                        }
                      }
                      return timeValue;
                    }}
                  />
                </ATMTable.Cell>
                <ATMTable.Cell>{val.tlSub}</ATMTable.Cell>
                <ATMTable.Cell>{val.subPole}</ATMTable.Cell>
                <ATMTable.Cell>{val.subDistId}</ATMTable.Cell>
              </ATMTable.Row>
            ))}
          </ATMTable.Body>
        </ATMTable>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

type IProps = {
  defaultValues?: ILerRequest;
  label?: string;
  withDates: boolean;
};

const LERRequestSwitchingETS: React.FC<IProps> = ({
  defaultValues,
  label,
  withDates,
}) => {
  const {
    state: { data },
  } = useLerRequestContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const { state, actions } = useLerRequestContext();
  const { getValues, setValue } = useATMFormContext<ILerRequestForm>();
  const formRef = useRef<HTMLFormElement>(null);
  const { state: switchingState, actions: switchingActions } =
    useSwitchingContext();

  useEffect(() => {
    if (!switchingState.list.length) {
      switchingActions.listGET({ limit: 0, page: 1 });
    }
  }, [switchingActions]);

  const loading = getLerRequestStatus(
    state,
    lerRequestActionTypes.LER_REQUEST_SORT_CREATE
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: ILerRequestEtsFormDispatchForm) => {
      if (data?.requestId) {
        const insertData = formData.etsDispatch.filter((val) => val.isChecked);

        if (insertData.length) {
          const result = await actions.createSwitchPOST(
            data?.requestId,
            insertData
          );

          if (result.payload) {
            const sortResponseList: ISortResponse[] = [];
            result.payload?.map((val) => {
              sortResponseList.push({
                ...val,
                date: val.sortTime,
                time: format24hTime(val.sortTime),
              });
            });
            setIsOpen(false);
            setValue(
              'sortResponses',
              orderBy(
                [...(getValues('sortResponses') as []), ...sortResponseList],
                ['sortTime', 'outBack'],
                ['asc', 'desc']
              )
            );
            actions?.dataGET(data?.requestId, undefined, true);
          }
        }
      }
    },
    [actions, data, setIsOpen, setValue, getValues]
  );

  if (!data) {
    return null;
  }

  return (
    <ATMModal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={isOpen}
      size="large"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <ATMButton content={label} secondary size="small" type="button" />
      }
    >
      <ATMModal.Header as="h2">{Lang.TTL_ETS_DISPATCH}</ATMModal.Header>
      <ATMModal.Content
        style={{ marginBottom: '100px', height: '70vh', overflowY: 'auto' }}
      >
        <ATMForm
          ref={formRef}
          onSubmit={handleSubmit}
          mode="onChange"
          validationSchema={LerRequestEtsFormDispatchFormSchema}
        >
          {(formProps) => {
            return (
              <ATMFormProvider {...formProps}>
                {withDates ? (
                  <FormContentByDates
                    defaultValues={
                      defaultValues || (getValues() as unknown as ILerRequest)
                    }
                    handleEnable={(value) => setIsEnabled(value)}
                    withDates={withDates}
                  />
                ) : (
                  <FormContent
                    defaultValues={
                      defaultValues || (getValues() as unknown as ILerRequest)
                    }
                    handleEnable={(value) => setIsEnabled(value)}
                  />
                )}
              </ATMFormProvider>
            );
          }}
        </ATMForm>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => setIsOpen(false)}
          type="button"
        />
        <ATMButton
          primary
          content="Add Switching Request"
          onClick={handleClick}
          type="button"
          loading={loading.fetching}
          disabled={!isEnabled || loading.fetching}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default LERRequestSwitchingETS;
